<template>
  <div class="login">
    <div class="form">
      <h3 class="title">后台管理系统</h3>
      <p class="welcome">欢迎登录</p>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="username">
          <el-input
            v-model="form.username"
            size="small"
            placeholder="请输入用户名"
          >
            <i slot="prefix" class="el-input__icon el-icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            type="password"
            size="small"
            placeholder="请输入密码"
          >
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="rememberFlag">
            <span class="remember">记住账号</span>
          </el-checkbox>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="small"
        class="loginBtn"
        :loading="isLoginLoading"
        @click="login('ruleForm')"
      >
        登录
      </el-button>
    </div>
  </div>
</template>

<script>
import { login } from '@/services/authorization'

export default {
  name: 'Login',
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      rememberFlag: false,
      isLoginLoading: false
    }
  },
  created () {
    this.initUserName()
  },
  methods: {
    initUserName () {
      const username = localStorage.getItem('username')
      if (username) {
        this.form.username = username
        this.rememberFlag = true
      }
    },
    login () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isLoginLoading = true
          try {
            const { data } = await login(this.form)
            this.rememberFlag ? localStorage.setItem('username', this.form.username) : localStorage.removeItem('username')
            this.$store.commit('setUser', data)
            this.$router.push(this.$route.query.redirect || '/babysitter')
            this.$message({
              type: 'success',
              message: '登录成功!'
            })
          } catch (err) {
            this.$message.error('用户名或密码错误')
          }
          this.isLoginLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: 20px 15px;
    width: 320px;
    border: 1px solid #ddd;
    border-radius: 10px;
    .title {
      margin: 0;
      font-size: 16px;
      text-align: center;
    }
    .welcome {
      font-size: 12px;
      border-bottom: 2px solid #409EFF;
      display: inline-block;
      margin: 20px 0;
      padding-bottom: 5px;
    }
    .remember {
      font-weight: normal;
      font-size: 12px;
    }
    .loginBtn {
      width: 100%;
    }
  }
}
</style>
